<template>
	<transition name="dialog">
		<div v-if="active" class="dialog-backdrop" @click="handleBackdropClick">
			<div class="modal-mask">
				<div class="modal-wrapper">
					<div class="dialog-container" @click.stop>
						<slot />
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'BaseDialog',
	props: {
		active: { type: Boolean, default: false },
	},
	methods: {
		handleBackdropClick() {
			this.$emit('update:active', false)
		},
	},
}
</script>

<style lang="scss" scoped>
.dialog-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
	.modal-mask {
		position: absolute;
		z-index: $Z-INDEX-LOGIN-POPUP;
		// margin-top: 224px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: table;
		transition: opacity 0.5s ease;

		.modal-wrapper {
			display: table-cell;
			vertical-align: middle;
			.dialog-container {
				max-width: gREm(600);
				box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
					0 24px 38px 3px rgba(0, 0, 0, 0.14),
					0 9px 46px 8px rgba(0, 0, 0, 0.12);

				margin-left: auto;
				margin-right: auto;
				padding: gREm(32);
				background-color: #fff;
				border-radius: 8px;
			}
		}
	}
}

@include media-max($media_small) {
	.dialog-backdrop {
		.modal-mask {
			.modal-wrapper {
				.dialog-container {
					max-width: 90vw;
				}
			}
		}
	}
}
</style>
