<template>
	<div class="dao-wrap">
		<div class="dao-detail-title">
			<img
				class="dao-title-icon"
				v-if="proposalItem.proposals_snapshotNetwork == '56' || proposalItem.proposals_snapshotNetwork == '97'"
				src="@/assets/img/bsc.svg"
				alt=""
			/>
			<img					
				class="dao-title-icon"
				v-else-if="proposalItem.proposals_snapshotNetwork == '1' || proposalItem.proposals_snapshotNetwork == '4'"
				src="@/assets/img/ethereum.png"
				alt=""
			/>
			<img				
				class="dao-title-icon"				
				v-else-if="proposalItem.proposals_snapshotNetwork == '137' || proposalItem.proposals_snapshotNetwork == '80001'"
				src="@/assets/img/polygon.png"
				alt=""
			/>
			<div class="dao-title">{{ proposalItem.proposals_title }}</div>
		</div>
		<div class="proposal-info">
			<div>
				<span class="proposal-category">
					{{ formatCategory(proposalItem.proposals_type) }}
				</span>
				<span
					class="proposal-status"
					:class="proposalItem.proposals_status"
				>
					{{ formatStatus(proposalItem.proposals_status) }}
				</span>
				<span class="proposal-time">
					<img src="@/assets/img/dao/time.svg" alt="" />
					{{
						formatTimeStamp(
							proposalItem.proposals_status,
							proposalItem.finish_at
						)
					}}</span
				>
			</div>
			<span class="report">
				<button class="btn-report" @click="reportProposal()">
					Report
				</button>
			</span>
		</div>
		<div class="content-detail">
			<div class="dao-detail">
				<div class="dao-content">
					{{ proposalItem.proposals_description }}
					<div
						v-if="proposalItem.proposals_type == 'promotion'"
					>
						<div v-if="isPromotionBrand" class="btn-contents">
							<button class="btn-content" @click="checkDetail()">
								CHECK DETAILS
							</button>
							<button class="btn-content" @click="visitBrandCenter()">
								VISIT BRAND CENTER
							</button>
						</div>
						<DAODetailImage :proposalItem="proposalItem" />
					</div>
				</div>
				<div class="proposal-tags" v-if="proposalItem.proposals_tags">
					<span class="tag-title">Tags: </span>
					<span class="tag" v-for="tag in arrTags" :key="tag"
						>#{{ tag }}</span
					>
				</div>
				<div class="h-bar"></div>
				<DAOComment :data="proposalItem" />
			</div>
			<div class="category-detail">
				<CategoryDetail
					:data="proposalItem"
					@handle-watchlist="handleWatchlist"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import DAOComment from '@/components/DaoDetail/DAOComment.vue'
import CategoryDetail from '@/components/DaoDetail/CategoryDetail.vue'
import DAODetailImage from '@/components/DaoDetail/DAODetailImage.vue'
import { getProposalByID } from '@/services/proposals.js'
import {
	removeProposalFromWatchlist,
	addProposalToWatchlist,
	getWatchlist,
} from '@/services/watchlist.js'
import { votingOption, Promotion_Brand, Promotion_Ondoll } from '@/services/config.js';

export default {
	name: 'DAODetail',
	components: { DAOComment, CategoryDetail, DAODetailImage },
	props: {},
	data() {
		return {
			snapshotId: this.$route.params.itemId,
			proposalItem: {},
			watchList: [],
			
		}
	},
	async created() {
		this.snapshotId = this.$route.params.itemId

		await this.fetchProposals()
	},
	computed: {
		arrTags() {
			return this.proposalItem.proposals_tags.split(' ')
		},
		isPromotionBrand() {
			return this.proposalItem.proposals_title === Promotion_Brand;
		},
		isPromotionOndoll() {
			return this.proposalItem.proposals_title === Promotion_Ondoll;
		}
	},
	methods: {
		checkDetail() {
			this.$router.push({
				name: 'Event-Vote'
			})
		},
		visitBrandCenter() {
			window.location.href = `dvisionnetwork://`;
		},
		reportProposal() {
			const acc = this.$store?.state?.wallet?.accounts[0]
			if (!acc) {
				this.$router.push({
					name: 'Catalyst',
				})
			} else {
				this.mxShowReportPopup()
			}
		},
		async handleWatchlist() {
			const acc = this.$store?.state?.wallet?.accounts[0]
			if (!acc) {
				this.$router.push({
					name: 'Catalyst',
				})
				return
			}
			if (
				this.watchList
					.map((item) => item.proposals_snapshotId)
					.indexOf(this.proposalItem.proposals_snapshotId) != -1
			) {
				await removeProposalFromWatchlist(
					this.proposalItem.proposals_snapshotId,
					this.$store.state.userInfo.wallet_addr
				)
					.then((res) => {
						this.fetchProposals()
					})
					.catch((err) => {
						console.log(err)
					})
			} else {
				let payload = {
					snapshotId: this.proposalItem.proposals_snapshotId,
					userId: this.$store.state.userInfo.wallet_addr,
				}
				addProposalToWatchlist(payload).then((res) => {
					this.fetchProposals()
				})
			}
		},
		async fetchProposals() {
			const user = this.$store.state.userInfo
			if (user.wallet_addr) {
				let payload = {
					userId: user.wallet_addr,
					pageIndex: 1,
					pageSize: 9999,
					status: undefined,
				}
				this.watchList = [];
				await getWatchlist(payload).then((res) => {
					if (res.count !== 0) {
						this.watchList = res.listProposals
					}
				})
			}

			await getProposalByID(this.snapshotId)
				.then((res) => {
					this.proposalItem = res[0]
					console.log("proposalItem", this.proposalItem);
					if (
						this.watchList
							.map((item) => item.proposals_snapshotId)
							.indexOf(this.proposalItem.proposals_snapshotId) !=
						-1
					)
						this.proposalItem.isPinned = true
					else this.proposalItem.isPinned = false
				})
				.catch((err) => {
					console.log(err)
				})
		},

		formatContent() {
			return this.proposalItem.proposals_description
				? this.proposalItem.proposals_description.replaceAll(
						'\n',
						'<br/>'
				  )
				: ''
		},

		formatStatus(status) {
			switch (status) {
				case 'inprogress':
					return 'Voting in Progress'
				case 'ended':
					return 'End of Voting'
				case 'proceeding':
					return 'Proceeding'
				case 'complete':
					return 'Complete'
				case 'rejected':
					return 'Rejected'
				default:
					break
			}
		},
		formatCategory(type) {
			switch (type) {
				case 'map-building':
					return 'Map & Building'
				case 'on-doll':
					return 'On-Doll'
				case 'item':
					return 'Item'
				case 'business':
					return 'Business'
				case 'game':
					return 'Game'
				case 'system':
					return 'System'
				case 'name-ban':
					return 'Name Ban'
				case 'etc':
					return 'ETC.'
				case 'promotion':
					return 'Promotion.'
				default:
					break
			}
		},
		formatTimeStamp(status, timeStamp) {
			let timeRemain = ''
			if (status === 'inprogress') {
				let eventdate = moment(
					new Date(Number(timeStamp * 1000))
				).valueOf()
				let now = moment().valueOf()

				let timeleft = eventdate - now
				let days = Math.round(timeleft / (1000 * 60 * 60 * 24))
				// let hours = Math.floor(
				// 	(timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				// )
				if(this.isPromotionOndoll) {
					timeRemain = '-';
				} else {
					if (days === 1) {
						timeRemain = `Ends in ${days} day`
					} else {
						timeRemain = `Ends in ${days} days`
					}
				}
				
			} else {
				timeRemain = `Ended ${moment(timeStamp * 1000).format(
					'MMM DD, YYYY'
				)}`
			}

			return timeRemain
		},
	},
}
</script>

<style lang="scss" scoped>
.dao-wrap {
	.dao-detail-title {
		display: flex;
		.dao-title-icon {
			height: 40px;
			width: 40px;
			margin-right: 5px;
			margin-top: 7px;
		}
	}

	.dao-title {
		height: auto;
		width: 100%;
		color: #000000;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 56px;
		word-break: break-word;
	}
	.proposal-info {
		display: flex;
		justify-content: space-between;
		.proposal-status {
			margin-top: 12px;
			margin-right: 12px;
			display: inline-block;
			border-radius: 4px;
			padding: 7px 16px;
			font-size: 14px;
			font-weight: 500;
			&.inprogress {
				color: #3b82f6;
				background: #e2ecfe;
				border: 1px solid #e2ecfe;
			}
			&.ended {
				color: #34d399;
				background: #e1f9f0;
				border: 1px solid #e1f9f0;
			}
			&.proceeding {
				color: #6c38ef;
				background: #eef2ff;
				border: 1px solid #eef2ff;
			}
			&.complete {
				color: #fcd34d;
				background: #fff9e5;
				border: 1px solid #fff9e5;
			}
			&.rejected {
				color: #f87171;
				background: #feeaea;
				border: 1px solid #feeaea;
			}
		}
		.proposal-category {
			margin-top: 12px;
			margin-right: 12px;
			display: inline-block;
			border-radius: 4px;
			border: 1px solid #747276;
			color: #747276;
			padding: 7px 16px;
			font-size: 14px;
			font-weight: 500;
		}
		.proposal-time {
			img {
				width: 20px;
				height: 20px;
				top: 5px;
			}
			margin-top: 12px;
			margin-right: 12px;
			font-size: 14px;
			font-weight: 400;
			color: #747276;
			display: inline-block;
		}
		.report {
			.btn-report {
				font-family: 'Montserrat';
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				color: #f9fafb;
				background: #6c38ef;
				border-radius: gREm(4);
				padding: gREm(7) gREm(16);
				margin-top: gREm(12);
				&:hover {
					box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
					transform: translateY(gREm(-5));
					transition: 0.2s;
					background: #5d23f0;
				}
			}
		}
	}
	.content-detail {
		display: grid;
		grid-template-columns: auto gREm(300);
		gap: gREm(20);
		.dao-detail {
			margin-top: gREm(32);
			max-width: 880px;
			.dao-content {
				white-space: pre-line;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: #454348;
				word-break: break-word;

				.btn-contents {
					text-align: center;
					.btn-content {
						font-family: 'Montserrat';
						font-weight: 500;
						font-size: 14px;
						line-height: 18px;
						color: #f9fafb;
						background: #6c38ef;
						border-radius: gREm(4);
						padding: gREm(7) gREm(16);
						margin: gREm(20) gREm(12) gREm(20) gREm(12);
						&:hover {
							box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
							transform: translateY(gREm(-5));
							transition: 0.2s;
							background: #5d23f0;
						}
					}
					
				}
			}
			.proposal-tags {
				font-size: 14px;
				margin-top: 24px;
				line-height: 2;
				.tag-title {
					color: black;
				}
				.tag {
					color: black;
					background: #efeeef;
					padding: 2px 5px;
					margin-left: 5px;
					margin-top: 5px;
					display: inline-block;
					word-break: break-word;
				}
			}
		}
	}
	.h-bar {
		margin-top: gREm(32);
		width: 100%;
		height: 1px;
		background-color: #dedede;
	}
}

@include media-max($media_small) {
	// 768
	.dao-wrap {
		.dao-title {
			font-size: 24px;
			line-height: 150%;
		}
		.proposal-info {
			display: block;
		}
		.content-detail {
			grid-template-columns: auto auto;
			.dao-detail {
				grid-column: span 2;
			}
			.category-detail {
				grid-column: span 2;
			}
		}
	}
}
</style>
