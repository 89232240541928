<template>
	<div class="title-box">
		<HeaderTab />
	</div>
	<div class="dao-detail">
		<div class="content-body-wrap">
			<img
				:src="'/img/icon/ArrowLeft.svg'"
				alt=""
				style="cursor: pointer"
				@click="goBack()"
			/>
			<div><DAODetail /></div>
		</div>
	</div>

	<Foot :theme="'white'" />
</template>

<script>
import DAODetail from '@/components/DaoDetail/DAODetail.vue'
import Foot from '@/components/FOOT.vue'
import HeaderTab from '@/components/HeaderTab.vue'

export default {
	name: 'Dao-Detail',
	components: {
		DAODetail,
		Foot,
		HeaderTab
	},
	props: {},
	mounted() {
		document.querySelector('.gnb-box-bg').scrollIntoView()
	},
	data() {
		return {
			activeTab: 'proposals',
		}
	},
	computed: {},
	methods: {
		goBack() {
			if (this.$route.query.backRoute) {
				this.$router.push({
					name: this.$route.query.backRoute,
					query: {
						curTab: this.$route.query.curTab,
						page: this.$route.query.page,
						status: this.$route.query.status,
					},
				})
			} else {
				if (window.history.state.back === null) {
					this.$router.push({
						name: 'Home',
					})
				} else {
					this.$router.go(-1)
				}
			}
		},
		setActiveTab(tab) {
			this.activeTab = tab
		},
	},
}
</script>

<style lang="scss" scoped>
.title-box {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: gREm(204);
	background-image: url('../assets/img/dao/header-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	.title-wrap {
		@include FLEXV(center, center);
		width: auto;
		height: gREm(95);
		margin-top: gREm(215);
		.title {
			height: auto;
			width: gREm(322);
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff, 600);
			text-align: center;
		}
		.sub-title {
			height: auto;
			width: auto;
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff);
			text-align: center;
		}
	}
}

.dao-detail {
	font-family: 'Montserrat';
	background: white;
	min-height: calc(100vh - 230px);
	.content-body-wrap {
		display: grid;
		grid-template-columns: gREm(32) auto;
		gap: gREm(20);

		width: 100%;
		max-width: 1180px;
		height: auto;
		margin: 0 auto;
		padding: 30px 20px 60px 20px;

		img {
			width: gREm(32);
			height: gREm(32);
			top: gREm(10);
		}
	}
}
@include media-max($media_small) {
	// 768
	.dao-detail {
		.content-body-wrap {
			padding: 20px !important;
			display: block;
			img {
				top: -5px;
			}
		}
	}
}
</style>
