<template>
	<div class="category" tabindex="-1">
		<div class="category-detail">
			<div class="category-flex" @click="toggleWatchlist()">
				<span v-if="data.isPinned">
					<img src="@/assets/img/dao/bookmarks.svg" alt="" />
					Remove from my Watchlist
				</span>
				<span v-else>
					<img src="@/assets/img/icon/bookmarks.svg" alt="" />
					Add to my Watchlist
				</span>
			</div>
		</div>
		<div class="category-detail">
			<div class="category-header">
				<div>Current Result</div>
				<BaseDialog :active="showSeeVote">
					<SeeVote
						:data="data"
						:seeVotes="seeVotes"
						:show="showSeeVote"
						@close-dialog="showSeeVote = !showSeeVote"
					/>
				</BaseDialog>
				<button
					type="button"
					class="purple-text"
					@click="handleClickSeeVotes"
				>
					See Votes
				</button>
			</div>
			<div class="category-content">
				<div>
					<div
						v-for="(item, index) in choice"
						:key="index"
					>
						<div v-if="result[index + 1]">
							<div class="content-detail">
								<div>{{item}}</div>
								<div>{{ result[index + 1].percent }}</div>
							</div>

							<div class="progress-bar">
								<div
									v-bind:style="
										setVoteColor(result[index + 1].percent, (index + 1) % 2 == 1 ? '#34D399' : '#f87171')
									"
								></div>
							</div>

							<div class="content-detail">
								<div
									@mouseover="isShowAgreeVP = true"
									@mouseleave="isShowAgreeVP = false"
									style="cursor: pointer"
								>
									{{
										result[index + 1].vp
											? numberLimitlength(result[index + 1].vp, 12)
											: 0
									}}
									VP
									<transition appear name="fade">
										<div
											v-if="isShowAgreeVP"
											class="user-menu-popup-wrap left"
										>
											<div class="user-menu-popup">
												{{
													result[index + 1].vp
														? result[index + 1].vp
														: 0
												}}
											</div>
										</div>
									</transition>
								</div>
								<div
									@mouseover="isShowAgreeVotes = true"
									@mouseleave="isShowAgreeVotes = false"
									style="cursor: pointer"
								>
									({{
										result[index + 1].votes > 1
											? `${numberLimitlength(
													result[index + 1].votes,
													12
												)} votes`
											: `${result[index + 1].votes} vote`
									}})
									<transition appear name="fade">
										<div
											v-if="isShowAgreeVotes"
											class="user-menu-popup-wrap right"
										>
											<div class="user-menu-popup">
												{{ result[index + 1].votes }}
											</div>
										</div>
									</transition>
								</div>
							</div>
							<div class="h-bar"></div>
						</div>
					</div>
				</div>

				<div class="acceptance" tabindex="0">
					<div class="voting-container" v-bind:style="setTab()">
						<div class="voting-status">
							<div class="voting-status-label">
								Acceptance Threshold
							</div>
							<div
								@mouseover="isShowAcceptance = true"
								@mouseleave="isShowAcceptance = false"
								style="cursor: pointer"
							>
								<span class="voting-status-detail">
									{{ acceptanceThresholdByType }}
								</span>
								<transition appear name="fade">
									<div
										v-if="isShowAcceptance"
										class="user-menu-popup-wrap"
									>
										<div class="user-menu-popup">
											{{ acceptanceThresold }}
										</div>
									</div>
								</transition>
							</div>
						</div>
						<div
							class="voting-status"
							style="
								position: absolute;
								left: 0px;
								top: 0px;
								transform: translateX(103%);
							"
						>
							<div class="voting-status-label">
								Needed for Acceptance
							</div>
							<div
								@mouseover="isShowNeeded = true"
								@mouseleave="isShowNeeded = false"
							>
								<span class="voting-status-detail">
									{{ neededForAcceptanceByType }}
								</span>
								<transition appear name="fade">
									<div
										v-if="isShowNeeded"
										class="user-menu-popup-wrap"
									>
										<div class="user-menu-popup">
											{{ neededForAcceptance }}
										</div>
									</div>
								</transition>
							</div>
						</div>
					</div>
				</div>
				<ol class="tab-dot">
					<li
						class="dot"
						aria-label="Page dot 1"
						v-for="tab in tabs"
						:key="tab.id"
						@click="setActive(tab.id)"
						v-bind:class="{ active: tab.isActive }"
					></li>
				</ol>
				<div v-if="curUser">
						<button
							v-for="(item, index) in choice"
							:key="index"
							:class="{ [`vote${(index % 2) + 1}`]: true, hadVote: voteStatus === (index + 1) }"
							@click="showConfirmVote(index + 1)"
							:disabled="
								data.proposals_status != 'inprogress' 
							"
						>
							{{item}}
						</button>

					<div class="content-detail">
						<div
							@mouseover="isShowVoting = true"
							@mouseleave="isShowVoting = false"
							style="cursor: pointer"
						>
							Voting with {{ numberLimitlength(balance, 12) }} VP
							<transition appear name="fade">
								<div
									v-if="isShowVoting"
									class="user-menu-popup-wrap right"
								>
									<div class="user-menu-popup">
										{{ balance }}
									</div>
								</div>
							</transition>
						</div>
						<div>
							<button
								class="purple-large-text"
								@click="manageVote()"
							>
								Manage
							</button>
						</div>
					</div>
				</div>
				<div v-else>
					<button class="btn-sign-in" @click="login">SIGN IN</button>
				</div>
			</div>
		</div>
		<div class="category-detail">
			<div class="category-header">Detail</div>
			<div class="category-content">
				<div class="content-detail">
					<div>Created by</div>
					<div class="purple-text" @click="gotoVotingPower()">
						<img alt="" :src="'/img/default.png'" />
						{{ formatName() }}
					</div>
				</div>
				<div class="h-bar"></div>
				<div class="content-detail">
					<div>Started</div>
					<div>
						{{ formatTimeStamp("Started", data.start_at) }}
					</div>
				</div>
				<div class="h-bar"></div>
				<div class="content-detail">
					<div>Ends</div>
					<div>
						{{ formatTimeStamp("Ends", data.finish_at) }}
					</div>
				</div>
				<div class="h-bar"></div>
				<div class="content-detail">
					<div>Snapshot</div>
					<div>
						<a
							class="purple-text"
							:href="`https://snapshot.org/#/${data.proposals_snapshotSpace}/proposal/${data.proposals_snapshotId}`"
							target="_blank"
							>#{{ formatSnap() }}
						</a>
					</div>
				</div>

				<div class="h-bar"></div>
			</div>
		</div>
		<div class="delete">
			<button
				@click="deteleProposal"
				v-if="
					(data.proposals_status === 'inprogress') &
					(data.proposals_user === curUser)
				"
			>
				Delete Proposal
			</button>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import BaseDialog from '@/components/BaseDialog.vue'
import {
	deleteProposal,
	getVotes,
	getScores,
	getVotePower,
	createMyVotes,
} from '@/services/proposals.js'
import SeeVote from '@/components/DaoDetail/SeeVote.vue'
import snapshot from '@snapshot-labs/snapshot.js'
import {
	string2Balance,
	fromHexToChainId,
	checkWalletAddr,
	checkNetwork,
	formatChainId,
	domain,
	vote2Types,
} from '@/features/Common.js'
import { MSG_METAMASK_1, MSG_METAMASK_2 } from '@/features/Messages.js'
import { getProvider } from '@/features/Connectors'
const { ethereum } = window
import _ from 'lodash'
import { METAMASK, COINBASE, BITSKI } from '@/features/Common'

import { Personal } from 'web3x/personal'
import { Address } from 'web3x-es/address'
import { votingOption, Promotion_Brand, Promotion_Ondoll } from '@/services/config.js';

import AppConfig from '@/App.Config.js'
var gConfig = AppConfig();

const hub = 'https://hub.snapshot.org'
const client = new snapshot.Client712(hub)
import Web3 from 'web3'

export default {
	name: 'CategoryDetail',
	components: {
		BaseDialog,
		SeeVote,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			loginBy: window.localStorage.getItem('loginBy'),
			acceptanceThresold: '0',
			neededForAcceptance: '0',
			balance: '0',
			result: {
				1: {
					percent: '0%',
					votes: 0,
					vp: 0,
				},
				2: {
					percent: '0%',
					votes: 0,
					vp: 0,
				}
			},
			seeVotes: [],
			voteStatus: '',
			showSeeVote: false,
			tabs: [
				{ id: 0, isActive: true },
				{ id: 1, isActive: false },
			],
			curtab: 1,
			curUser: this.$store.state.userInfo.wallet_addr,
			curAddr: this.$store.state.wallet.accounts[0],
			isShowAgreeVP: false,
			isShowAgreeVotes: false,
			isShowDisagreeVP: false,
			isShowDisagreeVotes: false,
			isShowAcceptance: false,
			isShowNeeded: false,
			isShowVoting: false,
			choice: []
		}
	},
	created() {
		

		this.setActive(0)
		this.setTab
	},
	mounted() {
		
		console.log({data: this.data});
		

		this.getEtherAccounts()		
		if (this.data?.proposals_snapshotId) {
			this.getVotes()
			this.getVotePower()
		}
		if (ethereum) {
			ethereum.on('accountsChanged', (accounts) => {
				this.curAddr = accounts[0]
			})
		}
	},
	watch: {
		data() {
			if (this.data.proposals_snapshotId) {
				this.choice = votingOption(this.data.proposals_type, this.data.proposals_title)[1];

				for(let i = 1; i <= this.choice.length; i++) {
					this.result[i] = {};
					this.result[i].vp = 0;
					this.result[i].votes = 0;
					this.result[i].percent = "0%";
				}

				this.getVotes()
				this.getVotePower()
			}
		},
		'$store.state.userInfo': function (newVal, oldVal) {
			this.curUser = newVal.wallet_addr
		},
	},
	methods: {
		numberLimitlength(number, length) {
			if (number.length > length) {
				return number.substring(0, length) + '...'
			} else {
				return number
			}
		},
		async getEtherAccounts() {
			try {
				if (this.loginBy === METAMASK || this.loginBy === COINBASE) {
					if (ethereum) {
						const accounts = await ethereum.request({
							method: 'eth_requestAccounts',
						})
						this.curAddr = accounts[0]
					}
				}
			} catch (e) {
				return []
			}
		},
		// click watchlist
		toggleWatchlist() {
			this.$emit('handle-watchlist')
		},
		async getVotePower() {
			this.mxShowLoading();
			const currentNetwork = window.localStorage.getItem('currentNetwork')
			const obj = {
				network: fromHexToChainId(currentNetwork),
				addresses: this.curUser,
				snapshot: this.data.proposals_snapshotProposal,
			}
			await getVotePower(obj).then((res) => {
				if (res && res.score) {
					let voteLength = res.score.length
					if (voteLength > 1) {
						this.balance =
							res.score[voteLength - 1].score > 0
								? string2Balance(
										res.score[voteLength - 1].score,
										0
								  )
								: '0'
					} else {
						this.balance =
							res.score[0].score > 0
								? string2Balance(res.score[0].score, 0)
								: '0'
					}
				}
				this.mxCloseLoading();
			})
		},
		// set vote color bar
		setVoteColor: function (width, color) {
			return {
				width: width,
				background: color,
				height: '10px',
				'border-radius': '3px',
			}
		},
		async getScores() {
			const space = 'yam.eth'
			const network = '1'
			console.log('seeVotes', this.seeVotes)
			const voters = this.seeVotes.map((item) => {
				return item.voter
			})
			const blockNumber = this.data.proposals_snapshotProposal
			const strategies = this.data.proposals_strategies
				? JSON.parse(this.data.proposals_strategies)
				: []
			if (strategies.length > 0) {
				snapshot.utils
					.getScores(space, strategies, network, voters, blockNumber)
					.then((scores) => {
						console.log('Scores', scores)
					})
			}
		},
		async castVote(choice) {
			let currentNetwork = window.localStorage.getItem('currentNetwork')
			currentNetwork = formatChainId(currentNetwork)
			const proposalNetwork = Number(
				JSON.parse(this.data.proposals_strategies)[0].network
			)
			if (proposalNetwork != currentNetwork) {
				this.mxCloseConfirmModal()
				this.mxShowToast(
					'Current network is not the same as the proposal network, please switch network!'
				)
			} else {
				if (!checkWalletAddr(this.curAddr, this.curUser)) {
					this.errorMessage = MSG_METAMASK_1
					this.mxShowToast(MSG_METAMASK_1)
					return
				}
				// if (!checkNetwork(currentNetwork)) {
				// 	this.errorMessage = MSG_METAMASK_2
				// 	this.mxShowToast(MSG_METAMASK_2)
				// 	return
				// }
				this.mxShowLoading('inf')
				try {
					//setProvider()
					const networkRPC = window.localStorage.getItem('networkRPC')
					const currentNetwork =
						window.localStorage.getItem('currentNetwork')
					const web3 = getProvider(
						this.loginBy,
						networkRPC,
						currentNetwork
					)
					let message = {
						space: this.data.proposals_snapshotSpace,
						proposal: this.data.proposals_snapshotId,
						choice: choice,
						metadata: JSON.stringify({}),
					}
					const [account] = await web3.listAccounts()
					if (this.loginBy === BITSKI) {
						const address = this.curUser
						if (!message.from) message.from = this.curUser
						if (!message.timestamp)
							message.timestamp = parseInt(
								(Date.now() / 1e3).toFixed()
							)
						// const sig = await new Personal(web3).sign(
						// 	message,
						// 	Address.fromString(account),
						// 	''
						// )
						// console.log('sig', sig)
						const signer = web3?.getSigner ? web3.getSigner() : web3
						const types = vote2Types
						const data = { domain, types, message }
						// console.log('data', data)

						//todo
						const sig = await signer._signTypedData(
							domain,
							types,
							message
						)
						console.log('Sign', { address, sig, data })
						const receipt = await this.send({ address, sig, data })
						console.log('receipt', receipt)
						if (receipt) {
							this.mxCloseLoading()
							this.mxCloseConfirmModal()
							this.voteSuccess()
							this.voteStatus = choice
							return;
						}
					} else {
						
						const receipt = await client.vote(web3, account, {
							space: this.data.proposals_snapshotSpace,
							proposal: this.data.proposals_snapshotId,
							type: 'vote',
							choice: choice,
							metadata: JSON.stringify({}),
						})
						if (receipt && receipt.id) {
							this.mxCloseLoading()
							this.mxCloseConfirmModal()
							this.voteSuccess()
							this.voteStatus = choice
						}
					}
				} catch (err) {
					this.mxCloseLoading()
					this.mxCloseConfirmModal()
					console.log('err', err)
					this.mxShowToast(err.error_description)
				}
			}
		},
		async send(envelop) {
			try {
				const url = `${hub}/api/msg`
				const init = {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(envelop),
				}
				return new Promise((resolve, reject) => {
					fetch(url, init)
						.then((res) => {
							if (res.ok) return resolve(res.json())
							throw res
						})
						.catch((e) => e.json().then((json) => reject(json)))
				})
			} catch (err) {
				this.mxCloseLoading()
				this.mxCloseConfirmModal()
				console.log('err', err)
				this.mxShowToast(err.error_description)
			}
		},
		async voteSuccess() {
			const obj = {
				isShow: true,
				title: 'Thank you for voting!',
				buttonTxt: 'OK',
				width: '585px',
				onClick: () => {
					
				}
			}
			this.mxShowSuccessModal(obj)
			await createMyVotes(
				this.data.proposals_snapshotId,
				Web3.utils.toChecksumAddress(this.curUser),
				this.balance.replaceAll(',', '')
			).then((res) => {
				this.getVotes()
				// this.fetchProposals()
				console.log(res)
			})
		},
		handleClickSeeVotes() {
			this.showSeeVote = !this.showSeeVote
		},
		async getVotes() {			
			await getVotes(this.data).then(async (res) => {		
				this.seeVotes = res.data.votes
				let vp = {};
				let totalVp = 0;

				for(let i = 1; i <= this.choice.length; i++) {
					vp[i] = {};
					vp[i].vp = 0;
					vp[i].votes = 0;
					vp[i].percent = "0%";
				}

				if (res.data.votes) {
					res.data.votes.map((item) => {
						vp[item.choice].vp += item.vp
						vp[item.choice].votes += 1
						totalVp += item.vp;
					})
				}

				const keys = Object.keys(vp);

				for (const key of keys) {
					this.result[key] = {};
					
					this.result[key].vp = string2Balance(vp[key].vp, 0);
					this.result[key].votes = vp[key].votes;
					
					const percent = totalVp === 0 ? 0 : (vp[key].vp / totalVp) * 100;
					this.result[key].percent = percent > 0 ? percent.toFixed(2) + '%' : '0%';
				}

				const acptThresold = Number(
					this.data.proposals_acceptanceThresold
				)
				this.acceptanceThresold = string2Balance(acptThresold, 0)

				if(!this.isPromotion) {
					const needForAcpt =
						vp['1'].vp - vp['2'].vp > 0
							? acptThresold - vp['1'].vp
							: acptThresold - vp['2'].vp
					this.neededForAcceptance =
						needForAcpt > 0 ? string2Balance(needForAcpt, 0) : '0'
				}
			})
		},
		async confirmDelete() {
			await deleteProposal(
				this.data.proposals_snapshotId,
				this.data.proposals_user
			)
				.then((res) => {
					const obj = {
						isShow: true,
						title: res.message
							? res.message
							: 'Your proposal is deleted',
						buttonTxt: 'OK',
						width: '585px',
					}
					this.mxCloseConfirmModal()
					this.mxShowSuccessModal(obj)
					this.$router.go(-1)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// set tab Acceptance Thresold/Needed for Acceptance
		setActive(id) {
			this.curtab = id
			if (id === 0) {
				this.tabs[0].isActive = true
				this.tabs[1].isActive = false
			} else {
				this.tabs[0].isActive = false
				this.tabs[1].isActive = true
			}
		},
		setTab: function () {
			return {
				transform:
					this.curtab === 0
						? 'translateX(0%)'
						: 'translateX(-103.25%)',
				transition: '0.2s',
			}
		},
		gotoVotingPower() {
			this.$router.push({
				name: 'Voting-Power',
				params: {
					address: this.data.proposals_user,
				},
			})
		},
		formatName() {
			let name = this.data.proposals_user ? this.data.proposals_user : ''
			let firstStr = name.substring(0, 5)
			let secondStr = name.substring(name.length - 4, name.length)
			return firstStr.concat('...', secondStr)
		},
		formatSnap() {
			return this.data.proposals_snapshotId
				? this.data.proposals_snapshotId.substring(0, 7)
				: ''
		},

		formatTimeStamp(key, timeStamp) {
			if(key == "Ends") {
				if(this.isPromotionOndoll) {
					return '-';
				}
			}
			return moment(new Date(Number(timeStamp * 1000))).format('LLL')
		},
		manageVote() {
			this.$router.push({
				name: 'Voting-Power',
				params: {
					address: this.$store.state.userInfo.wallet_addr,
				},
			})
		},
		showConfirmVote(vote) {
			console.log({vote, balance: this.balance});
			if(this.balance == 0) {
				const obj = {
					width: '712px',
					title: 'Vote Error',
					content:
						'Please retry after checking you voting power at the Voting Power tab.',
					buttonTxt: 'OK',
					isShow: true,
					onClick: () => {
						this.$router.push({
							name: 'Voting-Power',
							params: { address: this.$store.state.userInfo.wallet_addr },
						});
						this.mxCloseConfirmModal();
					},
				}
				this.mxShowConfirmModal(obj)
				return;
			}
			let curBalance = this.numberLimitlength(this.balance, 11)
			const obj = {
				isShow: true,
				title: 
				this.isPromotion 
				? `You currently have ${curBalance} VP.\nDo you really want to vote ${
					this.choice[vote - 1]
				}?`
				: `You currently have ${curBalance} VP.\nDo you really want to ${
					vote === 1 ? 'agree' : 'disagree'
				} this proposal?`,
				buttonTxt: 'Yes, I do!',
				buttonCancel: 'No. Let me think again',
				width: '585px',
				onClick: () => this.castVote(vote),
			}
			this.mxShowConfirmModal(obj)
		},
		deteleProposal() {
			const obj = {
				isShow: true,
				title: 'Do you really want to delete this proposal?',
				buttonTxt: 'Yes',
				buttonCancel: 'No',
				width: '585px',
				proposal: {
					proposalId: this.data.proposals_snapshotId,
					user: this.data.proposals_user,
				},
				onClick: () => this.confirmDelete(),
			}
			this.mxShowConfirmModal(obj)
		},
		login() {
			this.isShowNavbar = false
			this.$store.dispatch('showLoginPopup', true)
		},
	},
	computed: {
		isPromotionBrand() {
			return this.data.proposals_title === Promotion_Brand;
		},
		isPromotionOndoll() {
			return this.data.proposals_title === Promotion_Ondoll;
		},
		acceptanceThresholdByType() {
			if(this.data.proposals_type === "promotion") {
				return "Not Applicable"
			} else {
				return `${this.numberLimitlength(this.acceptanceThresold,12)}VP`
			}
		},
		neededForAcceptanceByType() {
			if(this.data.proposals_type === "promotion") {
				return "Not Applicable"
			} else {
				return `${this.numberLimitlength(this.neededForAcceptance,12)}VP`
			}
		},
		isPromotion() {
			return this.data.proposals_type === 'promotion';
		}
	}
}
</script>

<style lang="scss" scoped>
.category {
	& * {
		font-family: 'Montserrat';
		font-style: normal;
		color: #454348;
	}
	margin-top: gREm(32);
	.purple-text {
		color: #6c38ef;
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		cursor: pointer;
		img {
			width: gREm(20);
			height: gREm(20);
			top: gREm(5);
		}
	}

	.purple-large-text {
		color: #6c38ef;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
	}
	.progress-bar {
		display: block;
		background: #efeeef;
		border-radius: 3px;
	}
	.bar-blue {
		background: #34d399;
	}

	.bar-round {
		border-radius: 3px;
	}
	.bar-red {
		background: #f87171;
	}

	.user-menu-popup-wrap {
		position: absolute;
		@include FLEXV(flex-end, center);
		bottom: gREm(25);
		// min-width: gREm(100);
		z-index: 100;

		.user-menu-popup {
			@include FLEXV(flex-start, center);
			top: 0;
			width: 100%;
			padding: gREm(8);
			border-radius: gREm(10);
			border: solid gREm(1) #f1f1f1;
			background-color: #ffffff;
		}
		&.left {
			left: 0;
		}
		&.right {
			right: 0;
		}
	}

	.category-detail {
		// max-width: gREm(356);
		margin-bottom: gREm(16);
		background: #ffffff;
		border: 1px solid #efeeef;
		box-sizing: border-box;
		border-radius: 8px;
		.category-header {
			@include FLEX(space-between, flex-start);
			padding: 16px;
			font-weight: 600;
			font-size: 14px;
			line-height: 150%;
			color: #000000;
			width: 100%;

			background: #f9fafb;
		}

		.category-flex {
			padding: 16px;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			cursor: pointer;
			img {
				width: 20px;
				height: 20px;
				top: 5px;
			}
		}

		.category-content {
			display: flex;
			flex-direction: column;
			padding: 16px;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			.btn-reload {
				text-align: center;
				background: #6c38ef;
				padding: gREm(7px) gREm(16px);
				color: #ffffff;
				border-radius: 4px;
				cursor: pointer;
			}
			.btn-sign-in {
				width: 100%;
				height: gREm(40px);
				margin-top: 10px;
				border-radius: 4px;
				border: 1px solid #f87171;
				color: #f87171;
				&:hover {
					background: #f9fafb;
					transform: translateY(gREm(-5));
					transition: 0.3s;
					box-shadow: 0px 4px 10px rgba(52, 211, 153, 0.2);
					border-radius: 8px;
				}
			}
			.content-detail {
				@include FLEX(space-between, center);
				margin-top: gREm(16);
				margin-bottom: gREm(16);
				img {
					border-radius: 50%;
				}
			}

			@mixin vote {
				width: 100%;
				@include FLEX(center, center);
				background: #ffffff;
				border: 1px solid #eef2ff;
				border-radius: 8px;
				padding: gREm(8);
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				color: #000000;
				margin-top: gREm(16);
				cursor: pointer;
				&:hover {
					background: #f9fafb;
					transform: translateY(gREm(-5));
					transition: 0.3s;
					box-shadow: 0px 4px 10px rgba(52, 211, 153, 0.2);
					border-radius: 8px;
					color: #ffffff;
				}
			}

			.vote1 {
				@include vote;
				&:hover {
					background: #34d399;
				}
				&.hadVote {
					background: #34d399;
					color: #ffffff;
				}
				&:disabled {
					opacity: 0.6;
				}
			}

			.vote2 {
				@include vote;
				&:hover {
					background: #f87171;
				}
				&.hadVote {
					background: #f87171;
					color: #ffffff;
				}
				&:disabled {
					opacity: 0.6;
				}
			}

			.acceptance {
				margin-top: gREm(16);
				// margin-bottom: gREm(16);
				position: relative;
				overflow-x: hidden;
				height: 100px;
				width: 100%;
				.voting-container {
					width: 100%;
					height: 100%;
					position: absolute;
					&.voting-active {
						transform: translateX(-103.25%);
						transition: 0.2s;
					}
					.voting-status {
						background: #f9fafb;
						border-radius: 4px;
						border: 1px solid #eef2ff;
						padding: gREm(16);
						color: #000000;
						width: 100%;

						cursor: pointer;
						.voting-status-label {
							font-weight: 400;
							font-size: 12px;
							line-height: 150%;
						}
						.voting-status-detail {
							font-weight: 600;
							font-size: 14px;
							line-height: 150%;
						}
					}
				}
			}
			.tab-dot {
				line-height: 1;
				list-style: none;
				text-align: center;
				width: 100%;

				.dot {
					background: #333;
					border-radius: 50%;
					cursor: pointer;
					display: inline-block;
					opacity: 0.25;
					height: gREm(6);
					width: gREm(6);
					margin-right: gREm(8);
					&.active {
						opacity: 1;
					}
				}
			}
		}
	}
	.h-bar {
		width: 100%;
		height: 1px;
		background-color: #dedede;
	}
	.delete {
		display: flex;
		justify-content: center;

		button {
			padding: 7px 16px;
			border-radius: 4px;
			border: 1px solid #f87171;
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
			color: #f87171;
		}
	}
}
</style>
