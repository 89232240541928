import { API_DOMAIN } from './config'
import axios from 'axios'
import { fromHexToChainId } from '@/features/Common.js'

export function addProposalToWatchlist(payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_DOMAIN}/api/watchlist`, payload, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
export function removeProposalFromWatchlist(snapshotId, userId) {
	return new Promise((resolve, reject) => {
		axios
			.delete(
				`${API_DOMAIN}/api/watchlist?snapshotId=${snapshotId}&userId=${userId}`,
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
export function getWatchlist(payload) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/watchlist`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
				params: {
					...payload,
					chainId: fromHexToChainId(
						localStorage.getItem('currentNetwork')
					),
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
