<template>
	<div>
		<div class="dialog-title" v-if="seeVotes.length > 1">
			{{ seeVotes.length }} Votes
			<button type="button" class="black" @click="closeDialog(show)">
				<img :src="'/img/icon/close.svg'" alt="" />
			</button>
		</div>
		<div class="dialog-title" v-else>
			{{ seeVotes.length }} Vote
			<button type="button" class="black" @click="closeDialog(show)">
				<img :src="'/img/icon/close.svg'" alt="" />
			</button>
		</div>
		<div class="dialog-body">
			<table>
				<thead>
					<tr>
						<th>Voter</th>
						<th>Voted</th>
						<th>VP</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(seeVote, index) in seeVotes" :key="index">
						<td
							class="vote-user"
							@click="gotoVotingPower(seeVote.voter)"
						>
							<img src="/img/default.png" alt="" />
							{{ formatName(seeVote.voter) }}
						</td>
						<td
							:class="[
								(seeVote.choice % 2) === 1 ? 'vote-yes' : 'vote-no',
							]"
						>
							{{ choice[seeVote.choice - 1] }}
						</td>
						<td
							class="vote-vp"
							@mouseover="isShowVP[index] = true"
							@mouseleave="isShowVP[index] = false"
						>
							{{ formatVP(seeVote.vp, 11) }} VP
							<transition appear name="fade">
								<div
									v-if="isShowVP[index]"
									class="user-menu-popup-wrap"
								>
									<div class="user-menu-popup">
										{{ seeVote.vp.toLocaleString() }}
									</div>
								</div>
							</transition>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { votingOption } from '@/services/config.js';

export default {
	name: 'SeeVote',
	props: {
		data: Object,
		seeVotes: Array,
		show: Boolean,
	},
	created() {
		this.pushStatus()
	},
	data() {
		return {
			isShowVP: [],
			choice: []
		}
	},
	mounted() {
		this.choice = votingOption(this.data.proposals_type, this.data.proposals_title)[1];
		console.log({choice: this.choice, data: this.data, seeVotes: this.seeVotes})
	},
	methods: {
		closeDialog(data) {
			this.$emit('close-dialog', data)
		},
		formatName(name) {
			let firstStr = name.substring(0, 5)
			let secondStr = name.substring(name.length - 4, name.length)
			return firstStr.concat('...', secondStr)
		},
		formatVP(vp, length) {
			if (!vp) return ''
			const numberStr = Number(vp).toLocaleString()
			if (numberStr.length > length) {
				return numberStr.substring(0, length) + '...'
			} else {
				return Number(vp).toLocaleString()
			}
		},
		gotoVotingPower(add) {
			this.$router.push({
				name: 'Voting-Power',
				params: {
					address: add,
				},
			})
		},
		pushStatus() {
			this.seeVotes.forEach(element => {
			this.isShowVP.push(false)
		});
		}
	},
}
</script>

<style lang="scss" scoped>
.dialog-title {
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	color: #000000;
}

.dialog-body {
	overflow-y: auto;
	max-height: 50vh;
	&::-webkit-scrollbar {
		height: 0px;
	}
	table {
		margin-top: gREm(20);
		border-collapse: collapse;
		width: 100%;

		& thead {
			th {
				padding: gREm(10);
			}
		}
	}
	th {
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		color: #6c38ef;
		text-align: left;
	}
	tbody {
		tr {
			cursor: pointer;
			td {
				padding: 10px 10px;
			}
		}
	}
	.vote-user {
		color: black;
		display: flex;
		align-items: center;
		gap: gREm(16);
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		cursor: pointer;
		img {
			width: 32px;
			height: 32px;
			border-radius: 50%;
		}
	}
	.vote-yes {
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		color: #34d399 !important;
		padding-top: gREm(10);
	}
	.vote-no {
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		color: #f87171;
		padding-top: gREm(10);
	}
	.vote-vp {
		color: black;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		padding-top: gREm(10);
		min-width: 180px;
		.user-menu-popup-wrap {
			position: absolute;
			@include FLEXV(flex-end, center);
			bottom: gREm(40);
			// min-width: gREm(100);
			z-index: 100;
			left: 0;

			.user-menu-popup {
				@include FLEXV(flex-start, center);
				top: 0;
				width: 100%;
				padding: gREm(8);
				border-radius: gREm(10);
				border: solid gREm(1) #f1f1f1;
				background-color: #ffffff;
				color: black;
			}
		}
	}
}
</style>
