<template>
	<div class="dao-comment">
		<div class="dao-header">
			<div class="comment-count" v-if="listComment > 0">
				{{ listComment }} Comments
			</div>
			<div class="comment-count" v-else>Comment</div>
		</div>
		<div v-if="listComment > 0">
			<div v-for="comment in comments" :key="comment.id">
				<div class="comment">
					<div class="comment-user">
						<img :src="'/img/default.png'" alt="" />
						<div class="comment-info">
							<div class="comment-name">
								{{ formatName(comment.userId) }}
							</div>
							<div class="comment-time">
								{{ formatTimeStamp(comment.createdAt) }}
							</div>
						</div>
					</div>
					<div class="comment-detail">
						{{ comment.content }}
					</div>
				</div>
			</div>
		</div>
		<div class="no-comment" v-else>
			<div v-if="!isShowComment">
				<img :src="'/img/icon/file-missing.svg'" alt="" />
				<div>
					No comments yet, you have the chance to be the first one!
				</div>
			</div>
		</div>
		<div>
			<span class="load">
				<a
					@click="loadMore()"
					v-if="(listComment > 5) & (commentLength < listComment)"
					>Load more...</a
				>
			</span>
		</div>
		<div class="no-comment" v-if="isShowComment">
			<textarea
				v-model="comment"
				placeholder="Leave a comment..."
				@focusin="valuefocus = true"
				@focusout="valuefocus = false"
				:active="valuefocus ? 'on' : 'off'"
				maxlength="500"
			>
			</textarea>
			<span class="send">
				<button
					class="btn-send"
					@click="sendComment()"
					:disabled="comment.length === 0"
				>
					Send
				</button>
			</span>
		</div>
		<div class="comment-on" v-else @click="isShowComment = !isShowComment">
			<button class="discuss">Comment on this proposal</button>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { getCommentsByID, createComment } from '@/services/proposals.js'

export default {
	name: 'DAOComment',
	props: {
		data: Object,
	},
	data() {
		return {
			comments: [],
			listComment: 0,
			isShowComment: false,
			valuefocus: false,
			comment: '',
			commentLength: 5,
		}
	},
	watch: {
		data(val) {
			this.fetchComments()
		},
		comment() {
			if (this.comment.charAt(0) === ' ') {
				this.comment = this.comment.replace(' ', '')
			}
			this.comment = this.comment.replaceAll('  ', ' ')
		},
	},
	methods: {
		async fetchComments() {
			await getCommentsByID(this.data.proposals_id)
				.then((res) => {
					if (res.listComments < 5) {
						this.comments = res.count
					} else {
						this.comments = res.count.slice(0, this.commentLength)
					}
					this.listComment = res.listComments
				})
				.catch((err) => {
					console.log(err)
				})
		},
		loadMore() {
			this.commentLength += 5
			this.fetchComments()
		},
		formatTimeStamp(timeStamp) {
			return moment(new Date(timeStamp)).fromNow()
		},
		formatName(data) {
			let name = data ? data : ''
			let firstStr = name.substring(0, 5)
			let secondStr = name.substring(name.length - 4, name.length)
			return firstStr.concat('...', secondStr)
		},
		async sendComment() {
			const acc = this.$store?.state?.wallet?.accounts[0]
			if (!acc) {
				this.$router.push({
					name: 'Catalyst',
				})
			} else {
				let payload = {
					proposalId: this.data.proposals_id.toString(),
					userId: this.$store.state.userInfo.wallet_addr,
					parentId: this.data.proposals_user,
					content: this.comment,
				}
				this.mxShowLoading('inf')
				await createComment(payload)
					.then((res) => {
						this.mxCloseLoading()
						this.fetchComments()
						this.comment = ''

						document.querySelector('.dao-comment').scrollIntoView()
					})
					.catch((err) => {
						this.mxCloseLoading()
					})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.discuss {
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
	color: #6c38ef !important;
}
.dao-comment {
	& * {
		color: black;
		font-family: 'Montserrat';
		font-style: normal;
	}
	.dao-header {
		color: black;
		display: flex;
		justify-content: space-between;
		margin-top: gREm(24);
		margin-bottom: gREm(24);
		.comment-count {
			font-weight: 700;
			font-size: 18px;
			line-height: 150%;
			color: #17141a;
		}
		@extend .discuss;
	}

	.comment {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 24px;

		background: #f9fafb;
		border-radius: 8px;
		color: black;
		margin-bottom: gREm(16);

		.comment-user {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
			.comment-info {
				margin-left: gREm(16);
				.comment-name {
					font-weight: 700;
					font-size: 18px;
					line-height: 150%;
					color: #17141a;
				}
				.comment-time {
					font-weight: 400;
					font-size: 12px;
					line-height: 150%;
					color: #747276;
				}
			}
		}
		.comment-detail {
			white-space: pre-line;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			margin-top: gREm(16);
			word-break: break-word;
		}
	}

	.comment-on {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #6c38ef;
		border: 1px solid #6c38ef;
		border-radius: 8px;
		padding: gREm(8);
		margin-top: gREm(8);
		cursor: pointer;
		&:hover {
			box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
			transform: translateY(gREm(-5));
			transition: 0.2s;
		}
	}

	.no-comment {
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		text-align: center;
		margin-bottom: gREm(16);
		@extend .discuss;
	}
	textarea {
		border: 1px solid #efeeef;
		width: 100%;
		margin-top: gREm(8);
		padding: gREm(12) gREm(16);
		border-radius: gREm(8);
		height: gREm(172);
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		border: 1px solid #eef2ff;
		resize: none;
		&[active='on'] {
			box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
		}
	}
	.send {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.btn-send {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #f9fafb;
			background: #6c38ef;
			border-radius: gREm(4);
			padding: gREm(7) gREm(16);
			margin-top: gREm(16);
			margin-bottom: gREm(24);
			&:hover {
				box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
				transform: translateY(gREm(-5));
				transition: 0.2s;
			}
			&:disabled {
				opacity: 0.6;
			}
		}
	}

	.load {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: gREm(16);
		a {
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
</style>