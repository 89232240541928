<template>
  <div v-if="type === 1">
    <img 
      :src="getPromotionImgPath(0)"
    />
  </div>
  <div v-else-if="type === 2">
    <div class="grid-container">
    <div 
      class="grid-item" 
      v-for="(item, index) in choice" 
      :key="index"
    >
      <img :src="getPromotionImgPath(index)" alt="Item image" />
      <p>{{ item }}</p>
    </div>
  </div>
  </div>
</template>

<script>
import { votingOption, Promotion_Brand, Promotion_Ondoll } from '@/services/config.js';

export default {
  name: 'DAODetailImage',
  props: { proposalItem: Object, },
  data() {
    return {
      type: 0, // 1: 이미지 하나 , 2: 리스트
      choice: []
    }
  },
  mounted() {
    const option = votingOption(this.proposalItem.proposals_type, this.proposalItem.proposals_title);
    this.type = option[0];
    this.choice = option[1];
    console.error({type: this.type, choice: this.choice});
  },
  computed: {

  },
  methods: {
    getPromotionImgPath(index) {
      console.log({proposals_title: this.proposalItem.proposals_title});
			switch(this.proposalItem.proposals_title) {
				case Promotion_Brand:
        	return require(`@/assets/img/dao/promotion/brand.png`);
				case Promotion_Ondoll:
          const images = require.context('@/assets/img/dao/promotion/ondoll/', false, /\.png$/);
          return images(`./ondoll_${(index + 1).toString().padStart(2, '0')}.png`);
			}
		}
    // getPromotionImgPath(proposals_id) {
		// 	const images = require.context('@/assets/img/dao/promotion/', false, /\.jpg$/);
		// 	let imgPath = `@/assets/img/dao/promotion/${proposals_id}.jpg`;
		// 	console.log({imgPath});
			
		// 	try {
		// 		return images(`./${proposals_id}.jpg`);
		// 	} catch (e) {
		// 		return images(`./default.jpg`);  // Fallback image in case of an error
		// 	}
		// }
  }
}
</script>

<style lang="scss" scoped>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
    gap: 10px;

    .grid-item {
      width: 184px;
      height: 216px;
      border: 1px solid #d0d0d0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      // padding: 5px;

      img {
        width: 180px;
        height: 190px;
        object-fit: cover;
        margin: auto;
      }

      p {
        color: #4a4a4a;
        text-align: right;
        margin-top: auto;
        margin-right: 5px;
      }
    }
  }
</style>